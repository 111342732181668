import axios from 'axios';
import { lazy, Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
// Styles
import './App.css';

//REDUX
import { Helmet } from 'react-helmet-async';
import ArticleHelmet from './components/Hooks/ArticleHelmet';
import BuyPageHelmet from './components/Hooks/BuyPageHelmet';
import CustomHelmet from './components/Hooks/CustomHelmet';
import Loader from './components/UI/Loader/Loader';
import { LOAD_USER, LOGIN_SUCCESS } from './redux/reducers/auth';
import { LOAD_ALL_NUMBERS } from './redux/reducers/listings';
import SomethingWentWrong from './components/Error/SomethingWentWrong';
import ProfileHelmet from './components/Hooks/ProfileHelmet';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import BrandLandingHelmet from './components/Hooks/BrandLandingHelmet';
import BlogCollectionsHelmet from './components/Hooks/BlogCollectionsHelmet';

const SelectionsPage = lazy(() => import('./Pages/SelectionsPage'));
const BrandsLandingPage = lazy(() => import('./Pages/BrandsLandingPage'));
const InteriorDesignersPage = lazy(() =>
	import('./Pages/InteriorDesignersPage')
);

const Layout = lazy(() => import('./components/layout/Layout'));
const SellerDashboardPage = lazy(() => import('./Pages/SellerDashboardPage'));

const EditProfileForm = lazy(() =>
	import('./components/Forms/EditProfileForm')
);
const Article = lazy(() => import('./components/Blog/Article'));
const Blogs = lazy(() => import('./components/Blog/Blogs'));
const PrivateRoute = lazy(() => import('./components/Routing/PrivateRoute'));
const BusinessSellerRoute = lazy(() =>
	import('./components/Routing/BusinessSellerRoute')
);
const ScrollToTop = lazy(() => import('./components/layout/ScrollToTop'));
const ErrorBoundary = lazy(() => import('./utils/ErrorBoundary'));
const AskReview = lazy(() => import('./utils/AskReview'));
const BlockedAccount = lazy(() => import('./utils/BlockedAccount'));
const Landing = lazy(() => import('./Pages/Landing'));
const OrdersPage = lazy(() => import('./Pages/OrdersPage'));
const OrderPDFPage = lazy(() => import('./Pages/OrderPDFPage'));
const GuestPurchasesPage = lazy(() => import('./Pages/GuestPurchasesPage'));
const HelpArticlePage = lazy(() => import('./Pages/HelpArticlePage'));
const HowItWorksPage = lazy(() => import('./Pages/HowItWorksPage'));
const SellerLandingPage = lazy(() => import('./Pages/SellerLandingPage'));
const ShowListing = lazy(() => import('./Pages/ShowListing'));
const Home = lazy(() => import('./Pages/Home'));
const ShowUserProfile = lazy(() => import('./Pages/ShowUserProfile'));
const NotFoundPage = lazy(() => import('./Pages/NotFoundPage'));
const Register = lazy(() => import('./Pages/Register'));
const Login = lazy(() => import('./Pages/Login'));
const BuyerProtectionPage = lazy(() => import('./Pages/BuyerProtectionPage'));
const AddDeliveryPage = lazy(() => import('./Pages/AddDeliveryPage'));

const PasswordResetEmailPage = lazy(() =>
	import('./Pages/PasswordResetEmailPage')
);

const FavoritesPage = lazy(() => import('./Pages/FavoritesPage'));
const CheckoutPage = lazy(() => import('./Pages/CheckoutPage'));
const ImpactPage = lazy(() => import('./Pages/ImpactPage'));
const ContactPage = lazy(() => import('./Pages/ContactPage'));
const PayoutsPage = lazy(() => import('./Pages/PayoutsPage'));
const NewItemPage = lazy(() => import('./Pages/NewItemPage'));

const UserListingsPage = lazy(() => import('./Pages/UserListingsPage'));

const PrivacyPolicyPage = lazy(() => import('./Pages/PrivacyPolicyPage'));
const TermsOfUsePage = lazy(() => import('./Pages/TermsOfUsePage'));
const OrderCancellationPolicyPage = lazy(() =>
	import('./Pages/OrderCancellationPolicyPage')
);
const ReturnPolicyPage = lazy(() => import('./Pages/ReturnPolicyPage'));
const InfoPoliciesPage = lazy(() => import('./Pages/InfoPoliciesPage'));
const ProductPage = lazy(() => import('./Pages/ProductPage'));
const SettingsPage = lazy(() => import('./Pages/SettingsPage'));
const UnsubscribePage = lazy(() => import('./Pages/UnsubscribePage'));
const ConsignFormPage = lazy(() => import('./Pages/ConsignFormPage'));
const PinkBookPage = lazy(() => import('./Pages/PinkBookPage'));
const CollectionsPage = lazy(() => import('./Pages/CollectionsPage'));
const PurchasesPage = lazy(() => import('./Pages/PurchasesPage'));
const SalesPage = lazy(() => import('./Pages/SalesPage'));

function App() {
	useEffect(() => {
		const isFromGoogleReferrer = document.referrer.includes('google');
		const urlParams = new URLSearchParams(window.location.search);
		const searchQuery = urlParams.get('q');
		const isFromGoogleQueryParam = searchQuery !== null;

		if (isFromGoogleReferrer || isFromGoogleQueryParam) {
			window.location.reload();
		}
	}, []);

	if (process.env.NODE_ENV === 'production') {
		console.log = function () {};
	}

	const dispatch = useDispatch();

	const { search } = useLocation();
	let params = new URLSearchParams(search);
	const history = useHistory();
	const location = useLocation();
	const auth = useSelector((state) => state.auth);

	useEffect(() => {
		const isBot = /bot|crawl|slurp|spider|Prerender|facebookexternalhit/i.test(
			navigator.userAgent
		);
		if (!isBot) {
			process.env.NODE_ENV === 'production' &&
				window.analytics.page(location.pathname);
		}
	}, [location.pathname]);

	useEffect(() => {
		dispatch(LOAD_USER());
		const isBot = /bot|crawl|slurp|spider|Prerender|facebookexternalhit/i.test(
			navigator.userAgent
		);
		if (!isBot) {
			dispatch(LOAD_ALL_NUMBERS());
		}
		componentDidMount();
	}, []);

	const [showFeedbackModal, setShowFeedbackModal] = useState(false);

	const askForFeedback = async () => {
		//Ask feedback modal after 3 mins
		setTimeout(function () {
			if (
				!auth.isAuthenticated ||
				(auth.isAuthenticated && auth.user.askForFeedback)
			) {
				if (localStorage.getItem('feedbackGiven') == 'true') {
					return;
				} else {
					setShowFeedbackModal(true);
				}
			}
		}, 120000);
	};

	const redirectToOrderPage = async (id) => {
		await axios
			.get(`${process.env.REACT_APP_SERVER_API}/api/orders/${id}`)
			.then((res) => {
				if (res.data.buyer._id === auth.user._id) {
					history.push(
						`/purchases?&tab=purchase&selection=openPurchases&order=${id}`
					);
				} else {
					history.push(`/sales?&tab=sales&selection=openSales&order=${id}`);
				}
			});
	};

	useEffect(() => {
		if (
			auth.isAuthenticated &&
			params.get('order') &&
			params.get('order') !== 'null' &&
			params.get('order') !== 'undefined' &&
			!location.pathname.includes('purchases') &&
			!location.pathname.includes('sales') &&
			!location.pathname.includes('orders')
		) {
			redirectToOrderPage(params.get('order'));
		}
	}, [location, auth]);

	const [firstLoad, setFirstLoad] = useState();
	const enterAccount = async () => {
		await axios
			.post(`${process.env.REACT_APP_SERVER_API}/api/admin/enter-account`, {
				id: params.get('userAccountAdmin'),
			})
			.then(async (res) => {
				params.delete('userAccountAdmin');

				history.replace({
					pathname: location.pathname,
					search: '',
				});

				dispatch(LOGIN_SUCCESS(res.data));

				dispatch(LOAD_USER());
			})
			.catch((err) => {});

		setFirstLoad(true);
	};

	useEffect(() => {
		if (!auth.loading) {
			askForFeedback();
		}
		if (
			!auth.loading &&
			auth.isAuthenticated &&
			auth.user.admin &&
			params.get('userAccountAdmin') &&
			params.get('userAccountAdmin') !== 'undefined' &&
			params.get('userAccountAdmin') !== 'null' &&
			!firstLoad
		) {
			enterAccount();
		}

		if (
			params.get('utm_source') &&
			params.get('utm_source') !== 'undefined' &&
			params.get('utm_source') !== 'null' &&
			!localStorage.getItem('source')
		) {
			localStorage.setItem('source', params.get('utm_source'));
		}
	}, [auth, firstLoad]);

	// scroll To top on reload
	const componentDidMount = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	};

	return (
		<>
			<Suspense fallback={<Loader />}>
				<ErrorBoundary fallback={<SomethingWentWrong />}>
					<Layout>
						<ScrollToTop />
						<AskReview />
						<BlockedAccount />

						<Switch>
							<Route exact path="/">
								<Helmet>
									<title data-rh="true">
										Kashew - Buy and Sell Second Hand Furniture
									</title>
									<meta
										name="description"
										content="Kashew is the leading online marketplace for stylish used furniture. Shop unique pieces from professional resellers, enjoy nationwide delivery, and create your dream interior on a budget."
										data-rh="true"
									/>

									<meta
										name="google-site-verification"
										content="cju-KYWVBgSJZ_O7LEPlQtZJiI4U8YP5HeHNLCheSbo"
									/>

									<meta
										property="og:title"
										content="Kashew: Buy and Sell Second Hand Furniture"
										data-rh="true"
									/>
									<meta
										name="og:description"
										content="Kashew is the leading online marketplace for stylish used furniture. Shop unique pieces from professional resellers, enjoy nationwide delivery, and create your dream interior on a budget."
										data-rh="true"
									/>
									<meta
										property="og:image"
										content="https://ik.imagekit.io/kuj9zqlrx0or/Static/ogImg.png"
										data-rh="true"
									/>
									<meta
										property="og:image:alt"
										content="Kashew - Buy and Sell Second Hand Furniture"
										data-rh="true"
									/>

									<meta
										property="og:url"
										content="https://www.kashew.com"
										data-rh="true"
									/>
									<meta
										property="og:site_name"
										content="Kashew - Buy and Sell Second Hand Furniture"
										data-rh="true"
									/>
									<meta name="theme-color" content="#632340" data-rh="true" />
								</Helmet>
								<Landing />
							</Route>

							<Route path="/pages/:collection?">
								<BrandLandingHelmet />
								<BrandsLandingPage />
							</Route>

							<Route path="/selections/:selection?">
								<Helmet>
									<title>Used Designer Furniture - Best Deals | Kashew</title>
									<meta
										property="og:title"
										content="Used Designer Furniture - Best Deals | Kashew"
									/>
									<meta
										name="description"
										content="Shop the best deals on used designer furniture. Find unique pieces from professional resellers and enjoy nationwide delivery."
									/>
									<meta
										property="og:description"
										content="Shop the best deals on used designer furniture. Find unique pieces from professional resellers and enjoy nationwide delivery."
									/>

									<link
										rel="canonical"
										href="https://www.kashew.com/selections/top-picks"
									/>
								</Helmet>

								<SelectionsPage />
							</Route>

							<Route path="/collections/:categoryParam?/:optionParam?/:typeParam?">
								<BuyPageHelmet />
								<Home />
							</Route>
							<Route path="/brand/:brandParam?">
								<BuyPageHelmet />
								<Home />
							</Route>
							<Route path="/location/:locationParam?">
								<BuyPageHelmet />
								<Home />
							</Route>
							<Route path="/style/:styleParam?">
								<BuyPageHelmet />
								<Home />
							</Route>
							<Route path="/room/:roomParam?">
								<BuyPageHelmet />
								<Home />
							</Route>

							<Route exact path="/how-it-works">
								<Helmet>
									<title>How it Works | Kashew</title>
									<meta
										name="description"
										content="Kashew is the easiest way to buy and sell used furniture. Pickup and delivery available. Money-back guarantee. Shop from professional resellers and let us take care of the rest"
										data-rh="true"
									/>

									<meta
										property="og:description"
										content="Kashew is the easiest way to buy and sell used furniture. Pickup and delivery available. Money-back guarantee. Shop from professional resellers and let us take care of the rest"
									/>
									<meta property="og:title" content="How it Works | Kashew" />
									<link
										rel="canonical"
										href="https://www.kashew.com/how-it-works"
									/>
								</Helmet>
								<HowItWorksPage />
							</Route>

							<Route exact path="/about-us">
								<Helmet>
									<title> About Us | Kashew</title>
									<meta property="og:title" content="About Us | Kashew" />
									<link
										rel="canonical"
										href="https://www.kashew.com/about-us"
									/>
								</Helmet>
								<ImpactPage />
							</Route>

							<Route exact path="/buyer-protection">
								<Helmet>
									<title> Buyer Protection | Kashew</title>
									<meta
										property="og:title"
										content="Buyer Protection | Kashew"
									/>
									<link
										rel="canonical"
										href="https://www.kashew.com/buyer-protection"
									/>
								</Helmet>
								<BuyerProtectionPage />
							</Route>

							<Route exact path="/privacy-policy">
								<Helmet>
									<title>Privacy Policy | Kashew</title>
									<meta property="og:title" content="Privacy Policy | Kashew" />
									<link
										rel="canonical"
										href="https://www.kashew.com/privacy-policy"
									/>
								</Helmet>
								<PrivacyPolicyPage />
							</Route>

							<Route exact path="/terms-of-use">
								<Helmet>
									<title>Terms of Use | Kashew</title>
									<meta property="og:title" content="Terms of Use | Kashew" />
									<link
										rel="canonical"
										href="https://www.kashew.com/terms-of-use"
									/>
								</Helmet>
								<TermsOfUsePage />
							</Route>

							<Route exact path="/cancellation-policy">
								<Helmet>
									<title>Cancellation Policy | Kashew</title>

									<meta
										property="og:title"
										content="Cancellation Policy | Kashew"
									/>
									<link
										rel="canonical"
										href="https://www.kashew.com/cancellation-policy"
									/>
								</Helmet>
								<OrderCancellationPolicyPage />
							</Route>

							<Route exact path="/return-policy">
								<Helmet>
									<title>Return Policy | Kashew</title>

									<meta property="og:title" content="Return Policy | Kashew" />
									<link
										rel="canonical"
										href="https://www.kashew.com/return-policy"
									/>
								</Helmet>
								<ReturnPolicyPage />
							</Route>
							<Route exact path="/unsubscribe">
								<Helmet>
									<title>Unsubscribe | Kashew</title>

									<meta property="og:title" content="Unsubscribe | Kashew" />
									<link
										rel="canonical"
										href="https://www.kashew.com/unsubscribe"
									/>
								</Helmet>
								<UnsubscribePage />
							</Route>

							<Route exact path="/order/guest/:id">
								<Helmet>
									<title>Order summary | Kashew</title>

									<meta property="og:title" content="Order summary | Kashew" />
								</Helmet>
								<GuestPurchasesPage />
							</Route>

							<Route exact path="/buy">
								<Redirect to="/collections" />
							</Route>

							<Route exact path="/furniture-sellers">
								<Helmet>
									<title>Selling | Kashew</title>

									<meta property="og:title" content="Selling | Kashew" />

									<link
										rel="canonical"
										href="https://www.kashew.com/furniture-sellers"
									/>
								</Helmet>
								<SellerLandingPage />
							</Route>

							<Route exact path="/show/:id">
								<CustomHelmet />
								<ShowListing />
							</Route>

							<Route exact path="/product/:id/:title?">
								<CustomHelmet />
								<ProductPage />
							</Route>

							{/* This route needs to stay on top */}
							<PrivateRoute exact path="/profile/edit">
								<EditProfileForm />
							</PrivateRoute>

							<Route exact path="/profile/:id">
								<ProfileHelmet />
								<ShowUserProfile />
							</Route>

							<Route exact path="/register">
								<Helmet>
									<title>Register | Kashew</title>

									<meta property="og:title" content="Register | Kashew" />
									<link
										rel="canonical"
										href="https://www.kashew.com/register"
									/>
								</Helmet>
								<Register />
							</Route>

							<Route exact path="/login">
								<Helmet>
									<title>Login | Kashew </title>

									<meta property="og:title" content="Login | Kashew" />
									<link rel="canonical" href="https://www.kashew.com/login" />
								</Helmet>
								<Login />
							</Route>

							<Route exact path="/reset">
								<Helmet>
									<title>Reset Password | Kashew </title>

									<meta property="og:title" content="Reset Password | Kashew" />
									<link rel="canonical" href="https://www.kashew.com/reset" />
								</Helmet>
								<PasswordResetEmailPage />
							</Route>

							<Route exact path="/contact">
								<Helmet>
									<title>Contact Us | Kashew </title>

									<meta property="og:title" content="Contact Us | Kashew" />
									<link rel="canonical" href="https://www.kashew.com/contact" />
								</Helmet>
								<ContactPage />
							</Route>

							<Route exact path="/info">
								<Helmet>
									<title>Help Center | Kashew </title>

									<meta property="og:title" content="Help Center | Kashew" />
									<link rel="canonical" href="https://www.kashew.com/info" />
								</Helmet>
								<InfoPoliciesPage />
							</Route>

							<Route exact path="/help/article/:id">
								<HelpArticlePage />
							</Route>

							<Route exact path="/blog/:id">
								<ArticleHelmet />
								<Article />
							</Route>

							<Route exact path="/blog">
								<Helmet>
									<title>Blog | Kashew </title>
									<meta property="og:title" content="Blog | Kashew" />
									<link rel="canonical" href="https://www.kashew.com/blog" />
								</Helmet>
								<Blogs />
							</Route>

							<Route exact path="/blog/collections/:id">
								<BlogCollectionsHelmet />
								<CollectionsPage />
							</Route>

							<Route exact path="/consign-with-us">
								<Helmet>
									<title>Start Selling | Kashew </title>

									<meta property="og:title" content="Start Selling | Kashew" />
									<link
										rel="canonical"
										href="https://www.kashew.com/consign-with-us"
									/>
								</Helmet>
								<NewItemPage />
							</Route>

							<Route exact path="/consign-form">
								<Helmet>
									<title>Consign | Kashew </title>

									<meta property="og:title" content="Consign | Kashew" />
									<link
										rel="canonical"
										href="https://www.kashew.com/consign-form"
									/>
								</Helmet>
								<ConsignFormPage />
							</Route>
							<Route exact path="/legacyledger">
								<Helmet>
									<title>
										Legacy Ledger - Used Furniture & Decor | Kashew{' '}
									</title>

									<meta
										property="og:title"
										content="Legacy Ledger - Used Furniture & Decor | Kashew"
									/>
									<link
										rel="canonical"
										href="https://www.kashew.com/legacyledger"
									/>
								</Helmet>
								<PinkBookPage />
							</Route>

							<Route exact path="/order-summary/:id">
								<Helmet>
									<title>Order Summary | Kashew </title>

									<meta property="og:title" content="Order Summary  | Kashew" />
								</Helmet>

								<OrderPDFPage />
							</Route>

							<Route exact path="/trade">
								<Helmet>
									<title>Interior Designer Services | Kashew </title>
									<meta
										property="og:title"
										content="Interior Designer Services | Kashew"
									/>
									<link rel="canonical" href="https://www.kashew.com/trade" />
								</Helmet>
								<InteriorDesignersPage />
							</Route>

							<PrivateRoute exact path="/add-delivery/:id">
								<Helmet>
									<title>Add delivery | Kashew </title>

									<meta property="og:title" content="Add delivery | Kashew" />
								</Helmet>
								<AddDeliveryPage />
							</PrivateRoute>

							<PrivateRoute exact path="/payouts">
								<Helmet>
									<title>Payouts | Kashew </title>

									<meta property="og:title" content="Payouts | Kashew" />
									<link rel="canonical" href="https://www.kashew.com/payouts" />
								</Helmet>

								<PayoutsPage />
							</PrivateRoute>

							<PrivateRoute exact path="/orders">
								<Helmet>
									<title>My Orders | Kashew </title>

									<meta property="og:title" content="My Orders | Kashew" />
									<link rel="canonical" href="https://www.kashew.com/orders" />
								</Helmet>

								<OrdersPage />
							</PrivateRoute>

							<PrivateRoute exact path="/settings">
								<Helmet>
									<title>Settings | Kashew </title>

									<meta property="og:title" content="Settings | Kashew" />
									<link
										rel="canonical"
										href="https://www.kashew.com/settings"
									/>
								</Helmet>
								<SettingsPage />
							</PrivateRoute>

							<PrivateRoute exact path="/favorites">
								<Helmet>
									<title>Favorites | Kashew </title>

									<meta property="og:title" content="Favorites | Kashew" />
									<link
										rel="canonical"
										href="https://www.kashew.com/favorites"
									/>
								</Helmet>
								<FavoritesPage />
							</PrivateRoute>

							<Route exact path="/checkout">
								<Helmet>
									<title>Secure Checkout | Kashew </title>

									<meta
										property="og:title"
										content="Secure Checkout | Kashew"
									/>
									<link
										rel="canonical"
										href="https://www.kashew.com/checkout"
									/>
								</Helmet>
								<CheckoutPage />
							</Route>

							<PrivateRoute exact path="/purchases">
								<Helmet>
									<title>My Purchases | Kashew </title>

									<meta property="og:title" content="My purchases | Kashew" />
									<link
										rel="canonical"
										href="https://www.kashew.com/purchases"
									/>
								</Helmet>
								<PurchasesPage />
							</PrivateRoute>
							<PrivateRoute exact path="/sales">
								<Helmet>
									<title>My Sales | Kashew </title>

									<meta property="og:title" content="My Sales | Kashew" />
									<link rel="canonical" href="https://www.kashew.com/sales" />
								</Helmet>
								<SalesPage />
							</PrivateRoute>

							<BusinessSellerRoute exact path="/seller-dashboard">
								<Helmet>
									<title>Seller Dashboard | Kashew </title>

									<meta
										property="og:title"
										content="Seller Dashboard | Kashew"
									/>
									<link
										rel="canonical"
										href="https://www.kashew.com/seller-dashboard"
									/>
								</Helmet>
								<SellerDashboardPage />
							</BusinessSellerRoute>

							<PrivateRoute exact path="/your-listings">
								<Helmet>
									<title>My Listings | Kashew </title>

									<meta property="og:title" content="My Listings | Kashew" />
									<link
										rel="canonical"
										href="https://www.kashew.com/your-listings"
									/>
								</Helmet>
								<UserListingsPage />
							</PrivateRoute>

							<Route path="*">
								<Helmet>
									<title>Not Found | Kashew </title>

									<meta property="og:title" content="Not Found | Kashew" />
								</Helmet>
								<NotFoundPage status={404} />
							</Route>
						</Switch>
					</Layout>
				</ErrorBoundary>
			</Suspense>
		</>
	);
}

export default App;
